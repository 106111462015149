import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex, mobileContentSidePadding } from '../../styles/index'
import paper from '../../../static/paperBackground/paper.jpeg'
import {useGlobalDictionaryQuery} from '../../hooks/index'
import RichText from '../shared/RichText'
import Button from '../shared/Button'
import Image from 'gatsby-image'
import LinkTool from '../shared/LinkTool'
import Freckles from '../shared/FrecklesOverlappable'

export interface OverlappedImagesProps {
  imagesOverlapped: {
    imagesOverlapText: {
      json: string
    }
    imagesOverlapLink: {
      title: string
      target: {
        slug: string
      }
    }
    imagesOverlapMedia: {
      fluid: string
    }
    imagesOverlapMediaDesktop: {
      fluid: string
    }
  }
}

export default function HomeOverlappedImages(props: OverlappedImagesProps) {

  const {
    imagesOverlapped,
  } = props

  const {
    imagesOverlapText,
    imagesOverlapMedia,
    imagesOverlapMediaDesktop,
    imagesOverlapLink,
  } = imagesOverlapped

  const dictionary = useGlobalDictionaryQuery()

  //------- Breakpoints business --------------------------------------

  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    if(window.innerWidth >= 1024) setIsMobile(false)
  }, [])

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) return false
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true)
        return
      }
      setIsMobile(false)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Wrapper>
      <>
        <FrecklesWrapper>
          <Freckles canvasHeight={"100vh"} />
        </FrecklesWrapper>
        <TextBlock>
          <RichText json={imagesOverlapText.json} />
          <ButtonWrapper>
            <LinkTool to={`/${imagesOverlapLink.target.slug}/`}>
              <Button
                text={dictionary?.explore}
                Type="button"
                AriaLabel={"Send"}
                width="100%"
                widthDesk={desktopVW(240)}
                color={colors.black}
              />
            </LinkTool>
          </ButtonWrapper>
        </TextBlock>
        <ImagesBlock>
          <FrontImage>
            <FrontStyledImg
              fluid={
                isMobile
                  ? imagesOverlapMedia[1].fluid
                  : imagesOverlapMediaDesktop[1].fluid
              }
              loading="eager"
            />
          </FrontImage>
          <RearImage>
            <RearStyledImg
              fluid={
                isMobile
                  ? imagesOverlapMedia[0].fluid
                  : imagesOverlapMediaDesktop[0].fluid
              }
              loading="eager"
            />
          </RearImage>
        </ImagesBlock>
      </>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  height: auto;
  background: url(${paper});
  background-repeat: repeat;
  z-index: ${zIndex.backgrounds};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;

  ${desktopBreakpoint}{
    flex-direction: row;
  }
`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.dots};
  will-change: transform;
`;

const TextBlock = styled.div`
  height: auto;
  width: 100%;
  padding: ${mobileVW(50)} ${mobileContentSidePadding} ${mobileVW(20)} ${mobileContentSidePadding};
  z-index:${zIndex.surface};

  h6{
    font-family: 'messina';
    text-transform: uppercase;
    font-weight: 400;
    font-size: ${mobileVW(45)};
    line-height: 90%;
    color: ${colors.black};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(81)};
    }
  }

  ${desktopBreakpoint}{
    height: 100%;
    width: 50vw;
    padding: ${desktopVW(122)} 0 ${desktopVW(69)} ${desktopVW(61)};
  }
`;

const ButtonWrapper = styled.div`
  padding-top: ${mobileVW(20)};

  ${desktopBreakpoint}{
    padding-top: ${desktopVW(110)};
  }
`;

const ImagesBlock = styled.div`
  height: ${mobileVW(570)};
  width: 100%;
  position: relative;
  z-index: ${zIndex.surface};


  ${desktopBreakpoint}{
    height: 100%;
    width: 50vw;
  }
`;

const RearImage = styled.div`
  z-index: ${zIndex.surface -1};
  position: absolute;
  top: ${mobileVW(20)};
  left: 0;
  width: ${mobileVW(300)};
  height: auto;
  will-change: transform;

  ${desktopBreakpoint}{
    top: ${desktopVW(66)};
    right: ${desktopVW(180)};
    left: auto;
    width: ${desktopVW(540)};
  }
`;

const FrontImage = styled.div`
  z-index: ${zIndex.surface};
  position: absolute;
  top: ${mobileVW(280)};
  right: ${mobileVW(20)};
  width: ${mobileVW(200)};
  height: auto;
  will-change: transform;

  ${desktopBreakpoint}{
    top: ${desktopVW(564)};
    right: ${desktopVW(60)};
    width: ${desktopVW(360)};
  }
`;

const FrontStyledImg = styled(Image)`
  width: ${mobileVW(200)};
  height: auto;
  ${desktopBreakpoint}{
    width: ${desktopVW(360)};
  }
`;

const RearStyledImg = styled(Image)`
  width: ${mobileVW(300)};
  height: auto;
  ${desktopBreakpoint}{
    width: ${desktopVW(540)};
  }
`;