import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Loadable from 'react-loadable';
import { useInView } from 'react-intersection-observer';
import { SettingsContext } from '../contexts';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../styles/index';
import HomeHero from '../components/home/HomeHero';
import SEO from '../components/shared/SEO';
import HomeOverlappedImages from '../components/home/HomeOverlappedImages';
import { useGlobalComponentsQuery } from '../hooks/index';

const HomeCollectionSidebarSection = Loadable({
  loader: () => import('../components/home/HomeCollectionSidebarSection'),
  loading() {
    return <div />;
  },
});

const HomeCollectionGridSection = Loadable({
  loader: () => import('../components/home/HomeCollectionGridSection'),
  loading() {
    return <div />;
  },
});

const HomeParallaxSection = Loadable({
  loader: () => import('../components/home/HomeParallaxSection'),
  loading() {
    return <div />;
  },
});

const HomecollectionDisplaySection = Loadable({
  loader: () => import('../components/home/HomecollectionDisplaySection'),
  loading() {
    return <div />;
  },
});

export interface homeProps {
  data: {
    contentfulPageHome: {
      title: string;
      seoTitle: string;
      seoDescription: string;
      backgroundImgMobile: {
        title: string;
        fluid: string;
      };
      backgroundImgDesktop: {
        title: string;
        fluid: string;
      };
      heroCatchphrase: string;
      detailsColor: boolean;
      heroLink: {
        slug: string;
      };
      imagesOverlapped: {
        imagesOverlapText: {
          json: string;
        };
        imagesOverlapLink: {
          title: string;
          target: {
            slug: string;
          };
        };
        imagesOverlapMedia: {
          fluid: string;
        };
        imagesOverlapMediaDesktop: {
          fluid: string;
        };
      };
      collectionLeftSidebarSection: {
        mediaSection: {
          subtitle: {
            subtitle: string;
          };
          paragraph: {
            paragraph: string;
          };
        };
        linkImage: {
          fluid: string;
        };
        title: string;
        subtitle: string;
        slug: string;
      };
      collectionRightSidebarSection: {
        mediaSection: {
          paragraph: {
            paragraph: string;
          };
        };
        linkImage: {
          fluid: string;
        };
        title: string;
        subtitle: string;
        slug: string;
      };
      collectionGridSection: {
        title: string;
        items: {
          title: string;
          slug: string;
          productTag: string;
          shopifyData: {
            variants: {
              price: string;
              compare_at_price: string;
            };
          };
          thumbnailImages: {
            fluid: string;
          };
        };
      };
      parallaxSection: {
        topImage: {
          fluid: string;
        };
        midImage: {
          fluid: string;
        };
        bottomImage: {
          fluid: string;
        };
        text: {
          json: string;
        };
      };
      collectionDisplaySection: {
        slug: string;
        heroTopText: string;
        heroBottomText: string;
        linkImage: {
          fluid: string;
        };
      };
    };
  };
}

const Home = ({ data: { contentfulPageHome } }: homeProps) => {
  const {
    seoTitle,
    seoDescription,
    backgroundImgMobile,
    backgroundImgDesktop,
    heroCatchphrase,
    detailsColor,
    heroLink,
    imagesOverlapped,
    collectionLeftSidebarSection,
    collectionGridSection,
    collectionRightSidebarSection,
    parallaxSection,
    collectionDisplaySection,
  } = contentfulPageHome;

  const global = useGlobalComponentsQuery();

  // ------- LazyLoading business --------------------------------------

  const [pageMid, pageMidInView] = useInView({ threshold: 0 });
  const [trigger, triggerInView] = useInView({ threshold: 0 });

  const {
    renderHome,
    setRenderHome,
  }: {
    renderHome: any;
    setRenderHome: any;
  } = useContext(SettingsContext);

  useEffect(() => {
    if (renderHome) {
    } else if (pageMidInView || triggerInView) setRenderHome(true);
  }, [pageMidInView, triggerInView]);

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={`https:${global?.seoImage.file.url}`}
      />
      <HomeHero
        backgroundImageMobile={backgroundImgMobile}
        backgroundImageDesktop={backgroundImgDesktop}
        heroCatchphrase={heroCatchphrase}
        detailsColor={detailsColor}
        heroLink={heroLink}
      />
      <HomeOverlappedImages imagesOverlapped={imagesOverlapped} />
      <PageMid ref={pageMid}>
        <Trigger ref={trigger} />
        {renderHome && (
          <>
            <HomeCollectionSidebarSection
              collectionMedia={collectionLeftSidebarSection}
              leftContent
            />
            <HomeCollectionGridSection collectionGrid={collectionGridSection} />
            <HomeCollectionSidebarSection
              collectionMedia={collectionRightSidebarSection}
              leftContent={false}
            />
            <HomeParallaxSection parallaxSection={parallaxSection} />
            <HomecollectionDisplaySection collectionDisplaySection={collectionDisplaySection} />
          </>
        )}
      </PageMid>
    </>
  );
};

const Trigger = styled.div`
  position: absolute;
  top: ${mobileVW(-600)};
  left: 0;
  height: 200px;
  z-index: ${zIndex.basement};

  ${desktopBreakpoint} {
    top: ${desktopVW(-600)};
  }
`;

const PageMid = styled.div`
  min-height: 100vh;
  position: relative;
`;

export default Home;

export const HomePageQuery = graphql`
  query HomePage($id: String!) {
    contentfulPageHome(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      backgroundImgMobile {
        title
        fluid(maxWidth: 800, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      backgroundImgDesktop {
        title
        fluid(maxWidth: 1800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroCatchphrase
      detailsColor
      heroLink {
        target {
          ... on ContentfulPageAllProducts {
            slug
          }
          ... on ContentfulPageCollection {
            slug
          }
          ... on ContentfulPageContact {
            slug
          }
        }
      }
      imagesOverlapped {
        imagesOverlapText {
          json
        }
        imagesOverlapLink {
          title
          target {
            ... on ContentfulPageAllProducts {
              slug
            }
            ... on ContentfulPageCollection {
              slug
            }
            ... on ContentfulPagePhilosophy {
              slug
            }
          }
        }
        imagesOverlapMedia {
          fluid(maxWidth: 300, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        imagesOverlapMediaDesktop {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      collectionLeftSidebarSection {
        mediaSection {
          subtitle {
            subtitle
          }
          paragraph {
            paragraph
          }
        }
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title
        subtitle
        slug
      }
      collectionRightSidebarSection {
        mediaSection {
          paragraph {
            paragraph
          }
        }
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title
        subtitle
        slug
      }
      collectionGridSection {
        title
        items {
          title
          titleToShow
          slug
          productTag
          shopifyData {
            variants {
              price
              compare_at_price
            }
          }
          thumbnailImages {
            fluid(maxWidth: 810, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      parallaxSection {
        topImage {
          fluid(maxWidth: 480, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        midImage {
          fluid(maxWidth: 838, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        bottomImage {
          fluid(maxWidth: 1082, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        text {
          json
        }
      }
      collectionDisplaySection {
        slug
        heroTopText
        heroBottomText
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
