import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import {
  desktopBreakpoint,
  desktopVW,
  mobileVW,
  colors,
  zIndex,
  mobileContentSidePadding,
} from '../../styles/index';
import Button from '../shared/Button';
import { useGlobalDictionaryQuery, useLocale } from '../../hooks/index';
import LinkTool from '../shared/LinkTool';

interface Props {
  backgroundImageMobile: {
    title: string;
    fluid: string;
  };
  backgroundImageDesktop: {
    title: string;
    fluid: string;
  };
  heroCatchphrase: string;
  detailsColor: boolean;
  heroLink: {
    slug: string;
  };
}

export default function LandingPage(props: Props) {
  const {
    backgroundImageMobile,
    backgroundImageDesktop,
    heroCatchphrase,
    detailsColor,
    heroLink,
  } = props;

  const dictionary = useGlobalDictionaryQuery();

  // ------- Breakpoints business --------------------------------------

  const [isMobile, setIsMobile] = useState(true);


  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) setIsMobile(false);
  }, []);

  useEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setIsMobile(true);
        return;
      }
      setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const locale = useLocale();

  return (
    <Wrapper>
      <BackgroundImage
        fluid={isMobile ? backgroundImageMobile.fluid : backgroundImageDesktop.fluid}
        alt={backgroundImageMobile.title}
        loading="eager"
        fadeIn
      />
      <HeroFooter>
        <HeroCatchphrase detailsColor={detailsColor}>{heroCatchphrase}</HeroCatchphrase>
        <LinkTool to={`/${heroLink.target.slug}/`}>
          <Button
            text={dictionary?.explore}
            Type="button"
            AriaLabel="Send"
            width={mobileVW(350)}
            widthDesk={desktopVW(238)}
            color="rgba(255, 255, 255, 0.7)"
          />
        </LinkTool>
      </HeroFooter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 90vh;

  ${desktopBreakpoint} {
    height: 100vh;
  }
`;

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 90vh;
  z-index: ${zIndex.backgrounds};

  ${desktopBreakpoint} {
    height: 100vh;
  }
`;

const HeroFooter = styled.div`
  position: absolute;
  bottom: ${mobileVW(40)};
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 ${mobileContentSidePadding};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    bottom: ${desktopVW(70)};
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${desktopVW(60)};
  }
`;

const HeroCatchphrase = styled.h2`
  color: ${({ detailsColor }) => (detailsColor ? `${colors.white}` : `${colors.black}`)};
  font-family: 'messinaMono';
  font-size: ${mobileVW(31)};
  padding-bottom: ${mobileVW(30)};
  font-weight: 400;
  letter-spacing: -0.05em;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(27)};
    max-width: ${desktopVW(300)};
    padding-bottom: 0;
  }
`;
